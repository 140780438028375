export default {
  state: {
    blocked: false,
  },

  getters: {
    isSessionBlocked: (state) => state.blocked,
  },

  mutations: {
    setSessionBlocked(state, val) {
      state.blocked = !!val;
    },
  },
};
