import Snackbar from './Snackbar.vue'

let Constructor
let component = null

const create = () => {
  document.addEventListener('DOMContentLoaded', () => {
    let div = document.createElement('div')
    div.id = 'snackbar-' + Date.now()
    component = new Constructor();
    document.body.appendChild(div)
    //component.$on('close', function() {})
    component.$mount('#' + div.id)
  })
}
    
const $snack = (message) => {
  if (component) {
    if (typeof(message) == 'object')
    component.addMessage(message)
    else component.addMessage({text:message})
  }
}

const plugin = {
  install (Vue) {
    Constructor = Vue.extend(Snackbar)
    create()
    //Object.assign(Default, options)
    Vue.prototype.$snack = $snack
  }
}

export default plugin
