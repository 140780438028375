import { getAllCalendarEvents } from '@/api/Google';

export default {
  state: {
    meetingList: [],
  },

  getters: {
    getMeetingList: state => state.meetingList,
  },

  mutations: {
    setMeetingList(state, list) {
      state.meetingList = list;
    },
  },

  actions: {
    async refreshMeetingList(context, calendarIds = ['primary']) {
      return getAllCalendarEvents(calendarIds).then(events => {
        context.commit('setMeetingList', events);
        return events;
      });
    },
    clearMeetingList(context) {
      context.commit('setMeetingList', []);
    },
  },
};
