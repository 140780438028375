import moment from 'moment';

export default {
  state: {
    event: null,
  },

  getters: {
    getActiveEvent: state => state.event,
    // getId: state => (state.event ? state.event.id : null),
    // getStart: state => (state.event ? state.event.start : null),
    // getEnd: state => (state.event ? state.event.end : null),
    getActiveEventPublicRead: state => !!state.event?.public_read,
  },

  mutations: {
    setActiveEvent(state, event = null) {
      if (!event) state.event = null;
      else {
        const { evt, sample = false } = event;
        const impromptu = evt.organizer?.email == 'notes@meetric.app';
        // widen the time window by 15 minutes
        const start = moment(
          evt.start.dateTime ? evt.start.dateTime : evt.start.date
        );
        const end = moment(evt.end.dateTime ? evt.end.dateTime : evt.end.date);
        state.event = {
          id: evt.id,
          start: start.subtract(15, 'm').format(),
          end: end.add(15, 'm').format(),
          sample: sample,
          impromptu: impromptu,
          public_read: false,
        };
      }
    },
    setActiveEventOptions(state, options = {}) {
      if (!state.event) return;

      state.event = { ...state.event, ...options };
    },
  },
};
