import Vue from 'vue';
// import VueNativeSock from 'vue-native-websocket';

import '@/assets/styles/index.css';
import store from '@/store';
import router from '@/router';
import App from '@/App.vue';

// Local plugins
import Snackbar from '@/plugins/Snackbar';
import Notificationbar from '@/plugins/Notificationbar';
import GAuth from '@/plugins/GAuth';
import { segmentTrackRaw } from '@/api/Segment';

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: process.env.VUE_APP_GOOGLE_SCOPE,
  //prompt: 'select_account',
  ux_mode: 'popup',
};

// Vue.use(VueNativeSock, process.env.VUE_APP_SOCKET_URL, {
//   store: store,
//   format: 'json',
//   reconnection: false,
//   connectManually: true,
//   passToStoreHandler: function (eventName, event, next) {
//     if (eventName === 'SOCKET_onclose') {
//       console.log('socket_onclose', eventName, event);
//       next(eventName, event); // pass it to set isConnected=false in store
//     } else if (eventName === 'SOCKET_onopen') {
//       console.log('SOCKET_onopen', eventName, event);
//       next(eventName, event);
//       store.dispatch('resubscribe');
//     } else {
//       next(eventName, event);
//     }
//   },
// });
Vue.use(GAuth);
Vue.use(Snackbar);
Vue.use(Notificationbar);

// Vue developer tools
const developmentMode = process.env.NODE_ENV === 'development';
Vue.config.devtools = developmentMode;
Vue.config.productionTip = developmentMode;

Vue.gAuth.load(gauthOption).catch((e) => {
  document.getElementById('app').innerHTML = generateErrorMessage(e);
  segmentTrackRaw({
    event: 'Error',
    properties: { e, errorMsg: 'signin-cookie' },
  });
});

Vue.prototype.$scrollToTop = () => {
  window.scrollTo(0, 0);
};

var checkGauthLoad = setInterval(function () {
  if (Vue.gAuth.isInit) {
    clearInterval(checkGauthLoad);
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
}, 250);

function generateErrorMessage(e) {
  let text = '';
  if (e.error == 'idpiframe_initialization_failed') {
    text = `
    😢 Meetric sign-in couldn't be loaded. To resolve this, you must enable<br>
     third-party cookies in your browser or not use an incognito window.
`;
  } else {
    text = `Meetric app couldn't be loaded`;
  }

  return `
  <div style="display:flex; align-items: center;justify-content: center;height:100vh; text-align:center;">
  ${text}
  </div>
`;
}
