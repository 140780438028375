const defaultTheme = require('tailwindcss/defaultTheme')

const colorFn = (cssVar, { opacityVariable, opacityValue }) => {
  if (opacityValue !== undefined) {
    return `rgba(var(${cssVar}), ${opacityValue})`;
  }
  if (opacityVariable !== undefined) {
    return `rgba(var(${cssVar}), var(${opacityVariable}, 1))`;
  }
  return `rgb(var(${cssVar}))`;
};

module.exports = {
  purge: {
    enabled: process.env.VUE_APP_TAILWIND_PURGE == 'true',
    content: ['./src/**/*.vue', './src/**/*.js'],
  },
  theme: {
    screens: {
      lg: '950px',
      xl: '1300px', // TODO: fix this hack for the Icebreaker modal
    },
    fontSize: {
      xs: '.6rem', // Small text & email address
      sm: '.8rem', // Help text / text for small button / text for guest hover modal
      base: '.95rem', // Base text
      lg: '1.025rem', // Action page headings
      xl: '1.125rem', // H3 in notes + meeting titles
      '2xl': '1.25rem', // 'Good morning' heading + rounded buttons + Related heading
      '3xl': '1.5rem', // H2 in notes
      '4xl': '2.25rem', // H1 in notes
    },
    extend: {
      fontFamily: {
        sans: ['Inter', ...defaultTheme.fontFamily.sans],
      },
      colors: {
        white: (params) => colorFn('--white', params) /* always white */,
        base1: (params) => colorFn('--base-01', params) /* charcoal */,
        base2: (params) => colorFn('--base-02', params) /* bondi blue */,
        base3: (params) => colorFn('--base-03', params) /* coral */,
        base4: (params) => colorFn('--base-04', params) /* mintcream */,
        base5: (params) => colorFn('--base-05', params) /* always dark */,
        basebackground: (params) => colorFn('--basebackground', params),
        basenavbar: (params) => colorFn('--basenavbar', params),
        alertvalidation: (params) =>
          colorFn('--alert-validation', params) /* green */,
        alertwarning: (params) =>
          colorFn('--alert-warning', params) /* yellow */,
        alerterror: (params) => colorFn('--alert-error', params) /* red */,
        alertvalidationlight: (params) =>
          colorFn('--alert-validationlight', params) /* green */,
        alertwarninglight: (params) =>
          colorFn('--alert-warninglight', params) /* yellow */,
        alerterrorlight: (params) =>
          colorFn('--alert-errorlight', params) /* red */,
        grey1: (params) => colorFn('--grey-01', params) /* dark grey */,
        grey2: (params) => colorFn('--grey-02', params) /* medium grey */,
        grey3: (params) => colorFn('--grey-03', params) /* regular grey */,
        grey4: (params) =>
          colorFn('--grey-04', params) /* regular light grey */,
        grey5: (params) => colorFn('--grey-05', params) /* light grey */,
        grey6: (params) => colorFn('--grey-06', params) /* ultralight grey */,
      },
    },
  },
  plugins: [],
  darkMode: 'class',
};

// bondiblue button -< d8d8d8d text
// hover in settings ame as in action list
// modal header text d8d8d8
// modal ESD d2d1d1
// navbar line same white as icons
// link grey4 and date (so darker)
