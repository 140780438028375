import Vue from 'vue';
import Vuex from 'vuex';

import session from './session';
import profiles from './profiles';
import meetingList from './meetingList';
import activeEvent from './activeEvent';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    profiles,
    meetingList,
    user,
    activeEvent,
    session,
  },
});
