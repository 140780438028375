<template>
  <div>
    <!-- desktop -->
    <div class="hidden lg:flex">
      <transition name="popup">
        <div
          id="snackbar"
          class="w-full fixed opacity-75 bg-transparent flex"
          v-if="showSnackbar"
          style="z-index: 999"
        >
          <div
            class="flex rounded-xl bg-grey2 text-white dark:text-base5 justify-center m-auto px-6 py-3"
          >
            {{ queue[0].text }}
            <div class="ml-2 -mr-2" v-if="queue[0].emoji">
              <emoji-switch :icon="queue[0].emoji"></emoji-switch>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- mobile -->
    <div class="flex lg:hidden">
      <transition name="popup2">
        <div
          id="snackbar-mobile"
          class="w-full fixed opacity-85 bg-transparent flex"
          style="z-index: 999"
          v-if="showSnackbar"
        >
          <div
            class="flex rounded-xl bg-grey2 text-white dark:text-base5 justify-center m-auto px-6 py-3"
          >
            {{ queue[0].text }}
            <div class="ml-2 -mr-2" v-if="queue[0].emoji">
              <emoji-switch :icon="queue[0].emoji"></emoji-switch>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import EmojiSwitch from '@/components/EmojiSwitch';
export default {
  name: 'Snackbar',
  components: {
    EmojiSwitch,
  },
  data() {
    return {
      showSnackbar: false,
      duration: 3000,
      queue: [],
    };
  },
  methods: {
    addMessage(newMessage) {
      this.queue.push(newMessage);
      if (!this.showSnackbar) {
        this.showToast();
      }
    },
    showToast() {
      if (!this.showSnackbar) {
        this.showSnackbar = true;
      }
      setTimeout(() => {
        if (this.queue.length == 1) {
          this.showSnackbar = false;
          setTimeout(() => {
            this.queue.shift();
          }, 500);
        } else {
          this.queue.shift();
          this.showToast();
        }
      }, this.duration);
    },
  },
};
</script>

<style scoped>
#snackbar {
  bottom: 30px;
}
#snackbar-mobile {
  top: 50px;
}
.popup-enter-active {
  animation: popup-in 0.5s;
}
.popup-leave-active {
  animation: popup-in 0.5s reverse;
}

.popup2-enter-active {
  animation: popup-in-mobile 0.5s;
}
.popup2-leave-active {
  animation: popup-in-mobile 0.5s reverse;
}
@keyframes popup-in {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 0.75;
  }
}
@keyframes popup-in-mobile {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50px;
    opacity: 0.85;
  }
}
</style>
