import Notificationbar from './Notificationbar.vue';

let Constructor;
let component = null;

const create = () => {
  document.addEventListener('DOMContentLoaded', () => {
    let div = document.createElement('div');
    div.id = 'notificationbar-' + Date.now();
    component = new Constructor();
    document.body.appendChild(div);
    //component.$on('close', function() {})
    component.$mount('#' + div.id);
  });
};

const $notification = (content = '', props = {}) => {
  if (component) {
    if (typeof content == 'string' && content == '') {
      // hide the bar and reset to default
      component.closeBar();
      component.closable = false;
      component.type = 'error';
      component.errorMsg = '';
      return;
    }
    // set correct content (string or array of strings)
    if (Array.isArray(content)) {
      component.content = content;
    } else if (typeof content == 'string') {
      component.content = [{ text: content }];
    } else return;

    if (props && props.closable !== undefined)
      component.closable = props.closable;
    if (props && props.type !== undefined) component.type = props.type;
    if (props && props.errorMsg !== undefined)
      component.errorMsg = props.errorMsg;
    if (props && props.errorMsgDetails !== undefined)
      component.errorMsgDetails = props.errorMsgDetails;

    component.showBar();
  }
};

const plugin = {
  install(Vue) {
    Constructor = Vue.extend(Notificationbar);
    create();
    Vue.prototype.$notification = $notification;
  },
};

export default plugin;
