// Forked from https://github.com/guruahn/vue-google-oauth2

function installScript(apiUrl) {
  return new Promise((resolve) => {
    var script = document.createElement('script');
    script.src = apiUrl;
    script.onreadystatechange = script.onload = function () {
      if (!script.readyState || /loaded|complete/.test(script.readyState)) {
        setTimeout(function () {
          resolve();
        }, 500);
      }
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  });
}

var googleAuth = (function () {
  function installClient() {
    return installScript('https://apis.google.com/js/api.js');
  }

  function installOneTap() {
    return installScript('https://accounts.google.com/gsi/client');
  }

  function initClient(config) {
    return new Promise((resolve, reject) => {
      window.gapi.load('auth2', () => {
        window.gapi.auth2
          .init(config)
          .then(() => {
            resolve(window.gapi);
          })
          .catch((error) => {
            reject(error);
          });
      });
    });
  }

  function initOneTap(config, callback) {
    return new Promise((resolve) => {
      window.google.accounts.id.initialize({
        client_id: config.client_id,
        callback: callback,
        auto_select: false,
        cancel_on_tap_outside: false,
        context: 'signin', // options: signin, signup, use
      });
      window.google.accounts.id.prompt();
      resolve(window.google.accounts.id);
    });
  }

  function Auth() {
    if (!(this instanceof Auth)) return new Auth();
    this.GoogleAuth = null; /* window.gapi.auth2.getAuthInstance() */
    this.isInit = false;
    this.basicProfile = null;
    this.config = null;

    this.load = (config) => {
      this.config = config;
      return installClient()
        .then(() => {
          return initClient(config);
        })
        .then((gapi) => {
          this.GoogleAuth = gapi.auth2.getAuthInstance();

          if (this.isAuthorized) {
            this.basicProfile = this.GoogleAuth.currentUser
              .get()
              .getBasicProfile();
          }
          this.isInit = true;
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    };

    this.startOneTap = (successCallback, errorCallback) => {
      return new Promise((resolve, reject) => {
        installOneTap()
          .then(() => {
            return initOneTap(this.config, (result) => {
              const hint = parseJwt(result.credential).email;
              this.signInOneTap(hint, successCallback, errorCallback).then(
                (googleUser) => {
                  resolve(googleUser);
                }
              );
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    };

    this.signInSelectAccount = (successCallback, errorCallback) => {
      const options = { prompt: 'select_account' };
      return this.signIn(options, successCallback, errorCallback);
    };

    this.signInOneTap = (hint, successCallback, errorCallback) => {
      const options = { login_hint: hint };
      return this.signIn(options, successCallback, errorCallback);
    };

    this.signIn = (options, successCallback, errorCallback) => {
      return new Promise((resolve, reject) => {
        if (!this.GoogleAuth) {
          if (typeof errorCallback === 'function') errorCallback(false);
          reject(false);
          return;
        }

        this.GoogleAuth.signIn(options)
          .then((googleUser) => {
            if (this.isAuthorized) {
              this.basicProfile = this.GoogleAuth.currentUser
                .get()
                .getBasicProfile();
            }
            if (typeof successCallback === 'function')
              successCallback(googleUser);
            resolve(googleUser);
          })
          .catch((error) => {
            if (typeof errorCallback === 'function') errorCallback(error);
            reject(error);
          });
      });
    };

    this.requestCalendarWrite = () => {
      const googleUser = this.GoogleAuth?.currentUser?.get();

      return googleUser?.grant({
        scope: process.env.VUE_APP_GOOGLE_SCOPE_WRITE,
      });
    };

    this.getAuthCode = (successCallback, errorCallback) => {
      return new Promise((resolve, reject) => {
        if (!this.GoogleAuth) {
          if (typeof errorCallback === 'function') errorCallback(false);
          reject(false);
          return;
        }

        const email = this.basicProfile?.getEmail();
        this.GoogleAuth.grantOfflineAccess({
          login_hint: email,
          scope: process.env.VUE_APP_GOOGLE_OFFLINE_SCOPE,
        })
          .then(function (resp) {
            if (typeof successCallback === 'function')
              successCallback(resp.code);
            resolve(resp.code);
          })
          .catch(function (error) {
            if (typeof errorCallback === 'function') errorCallback(error);
            reject(error);
          });
      });
    };

    this.closeOneTap = () => {
      window.google.accounts.id.cancel();
    };

    this.signOut = (successCallback, errorCallback) => {
      return new Promise((resolve, reject) => {
        if (!this.GoogleAuth) {
          if (typeof errorCallback === 'function') errorCallback(false);
          reject(false);
          return;
        }
        this.GoogleAuth.signOut()
          .then(() => {
            if (typeof successCallback === 'function') successCallback();

            this.basicProfile = null;
            resolve(true);
          })
          .catch((error) => {
            if (typeof errorCallback === 'function') errorCallback(error);
            reject(error);
          });
      });
    };
  }

  // make isAuthorized getter to always get corret val (when logout in another tab)
  Object.defineProperty(Auth.prototype, 'isAuthorized', {
    get: function () {
      return this.GoogleAuth?.isSignedIn?.get() ?? false;
    },
  });

  return new Auth();
})();

function installGoogleAuthPlugin(Vue) {
  /* eslint-disable */
  //set config
  /*let GoogleAuthConfig = null
  let GoogleAuthDefaultConfig = { scope: 'profile email' }
  let prompt = 'select_account'
  if (typeof options === 'object') {
    GoogleAuthConfig = Object.assign(GoogleAuthDefaultConfig, options)
    if (options.scope) GoogleAuthConfig.scope = options.scope
    if (options.prompt) prompt = options.prompt
    if (!options.clientId) {
      console.warn('clientId is required')
    }
  } else {
    console.warn('invalid option type. Object type accepted only')
  }
  */

  //Install Vue plugin
  Vue.gAuth = googleAuth;
  Object.defineProperties(Vue.prototype, {
    $gAuth: {
      get: function () {
        return Vue.gAuth;
      },
    },
  });
  //Vue.gAuth.load(GoogleAuthConfig, prompt)
}

export default installGoogleAuthPlugin;

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
