import Vue from 'vue';
import { getMeetricProfile } from '@/api/Cherry';

export default {
  state: {
    meetricProfiles: {},
  },

  // You can use it as a state getter function (probably the best solution)
  getters: {
    profile: (state) => (email) => {
      return state.meetricProfiles[email];
    },
    getName: (state) => (email) => {
      const profile = state.meetricProfiles[email];
      if (profile?.profile)
        return profile.profile.name ? profile.profile.name : email;
      else return email;
    },
  },

  // Mutation for when you use it as state property
  mutations: {
    saveProfile(state, payload) {
      Vue.set(state.meetricProfiles, payload.username, {
        profile: payload.profile,
        loading: payload.loading,
      });
    },
  },

  actions: {
    fetchProfile: function (context, username) {
      context.commit('saveProfile', {
        username: username,
        loading: true,
        profile: null,
      });
      return getMeetricProfile(username)
        .then((response) => {
          let profile = !response.error ? response : null;
          context.commit('saveProfile', {
            username: username,
            loading: false,
            profile: profile,
          });
        })
        .catch((error) => {
          console.error('Error getting meetric profile', error);
          context.commit('saveProfile', {
            username: username,
            loading: false,
            profile: null,
          });
        });
    },
  },
};
